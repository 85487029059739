/*
 * @Author: your name
 * @Date: 2021-08-30 14:08:56
 * @LastEditTime: 2021-11-24 14:43:36
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \foundao_work\xiangan_enterprise\src\requestProvider.tsx
 */
// 全局useRequest配置
import { UseRequestProvider } from 'umi';
import { ConfigProvider, message } from 'antd';
import { DndProvider } from 'react-dnd';
import { AliveScope } from 'react-activation';
import { HTML5Backend } from 'react-dnd-html5-backend';
import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
export default function Index({ children }) {
  console.log('ininin');

  message.config({
    maxCount: 3,
  });

  return (
    <AliveScope>
      <UseRequestProvider
        value={{
          // formatResult(res) {
          //   console.log(res, 'format');
          // },
          onSuccess: (data: any, params: any) => {
            console.log(data, params, '全局请求成功');
          },
          onError: (err: Error, params: any) => {
            console.log(err, params);
          },
          // refreshOnWindowFocus: true, //全局重新聚焦再次请求接口
        }}
      >
        {/* <ConfigProvider locale={zhCN}> */}
        <DndProvider backend={HTML5Backend}>{children}</DndProvider>
        {/* </ConfigProvider> */}
      </UseRequestProvider>
    </AliveScope>
  );
}
