// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from 'D:/xiangan/xiangan-enterprise/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/login",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Login__index' */'@/pages/Login/index'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/noAuth",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ErrorPage__noAuth' */'@/pages/ErrorPage/noAuth'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/404",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__basicLayouts' */'@/layouts/basicLayouts'), loading: LoadingComponent}),
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/wrappers/auth'), loading: LoadingComponent})],
    "title": "主页",
    "routes": [
      {
        "path": "/stat",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__stat__stat-sale' */'@/pages/stat/stat-sale'), loading: LoadingComponent}),
        "exact": true,
        "title": "销售统计"
      },
      {
        "path": "/stat/stat_sale",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__stat__stat-sale' */'@/pages/stat/stat-sale'), loading: LoadingComponent}),
        "exact": true,
        "title": "销售统计"
      },
      {
        "path": "/stat/stat_customer",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__stat__customer-stat' */'@/pages/stat/customer-stat'), loading: LoadingComponent}),
        "exact": true,
        "title": "客户销售统计"
      },
      {
        "path": "/stat/stat_worker",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__stat__worker-stat' */'@/pages/stat/worker-stat'), loading: LoadingComponent}),
        "exact": true,
        "title": "家政员统计"
      },
      {
        "path": "/stat/stat_resume",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__stat__resume-stat' */'@/pages/stat/resume-stat'), loading: LoadingComponent}),
        "exact": true,
        "title": "简历统计"
      },
      {
        "path": "/customer",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Customer__Statistics' */'@/pages/Customer/Statistics'), loading: LoadingComponent}),
        "exact": true,
        "title": "客户管理"
      },
      {
        "path": "/customer/customerNeed_stat",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Customer__Statistics' */'@/pages/Customer/Statistics'), loading: LoadingComponent}),
        "exact": true,
        "title": "客户数据统计"
      },
      {
        "path": "/customer/customerNeed_public",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Customer__Sea' */'@/pages/Customer/Sea'), loading: LoadingComponent}),
        "exact": true,
        "title": "公海"
      },
      {
        "path": "/customer/customerNeed_purpose",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Customer__Intention' */'@/pages/Customer/Intention'), loading: LoadingComponent}),
        "exact": true,
        "title": "意向客户"
      },
      {
        "path": "/customer/customerNeed_signed",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Customer__Signed' */'@/pages/Customer/Signed'), loading: LoadingComponent}),
        "exact": true,
        "title": "已签约客户"
      },
      {
        "path": "/customer/customerNeed_afterService",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Customer__AfterSale' */'@/pages/Customer/AfterSale'), loading: LoadingComponent}),
        "exact": true,
        "title": "客户售后"
      },
      {
        "path": "/customer/customerNeed_clue",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Customer__Clue' */'@/pages/Customer/Clue'), loading: LoadingComponent}),
        "exact": true,
        "title": "客户线索"
      },
      {
        "path": "/customer/contract",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Customer__Contract' */'@/pages/Customer/Contract'), loading: LoadingComponent}),
        "exact": true,
        "title": "客户合同"
      },
      {
        "path": "/customer/contract/details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Customer__Contract__Details' */'@/pages/Customer/Contract/Details'), loading: LoadingComponent}),
        "exact": true,
        "title": "客户合同详情"
      },
      {
        "path": "/customer/workBench",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Customer__WorkBench' */'@/pages/Customer/WorkBench'), loading: LoadingComponent}),
        "exact": true,
        "title": "工作台"
      },
      {
        "path": "/customer/workBenchNew",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Customer__WorkBenchNew' */'@/pages/Customer/WorkBenchNew'), loading: LoadingComponent}),
        "exact": true,
        "title": "工作台新"
      },
      {
        "path": "/customer/contract/edit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Customer__Contract__Edit' */'@/pages/Customer/Contract/Edit'), loading: LoadingComponent}),
        "exact": true,
        "title": "客户合同编辑"
      },
      {
        "path": "/customer/order",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Customer__Order' */'@/pages/Customer/Order'), loading: LoadingComponent}),
        "exact": true,
        "title": "客户订单"
      },
      {
        "path": "/customer/customerNeed_all",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Customer__CustomerNeedAll' */'@/pages/Customer/CustomerNeedAll'), loading: LoadingComponent}),
        "exact": true,
        "title": "客户订单"
      },
      {
        "path": "/customer/orderConfirm",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Customer__OrderConfirm' */'@/pages/Customer/OrderConfirm'), loading: LoadingComponent}),
        "exact": true,
        "title": "订单确认"
      },
      {
        "path": "/worker",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Worker__Black' */'@/pages/Worker/Black'), loading: LoadingComponent}),
        "exact": true,
        "title": "家政员管理"
      },
      {
        "path": "/worker/workerKind_stat",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Worker__Statistics' */'@/pages/Worker/Statistics'), loading: LoadingComponent}),
        "exact": true,
        "title": "家政员数据统计"
      },
      {
        "path": "/worker/workerKind_recommend",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Worker__Recommend' */'@/pages/Worker/Recommend'), loading: LoadingComponent}),
        "exact": true,
        "title": "家政员推荐"
      },
      {
        "path": "/worker/workerBlacklist",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Worker__Black' */'@/pages/Worker/Black'), loading: LoadingComponent}),
        "exact": true,
        "title": "拉黑家政员"
      },
      {
        "path": "/worker/workerKind_signed",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Worker__Signed' */'@/pages/Worker/Signed'), loading: LoadingComponent}),
        "exact": true,
        "title": "已签约家政员"
      },
      {
        "path": "/worker/workerKind_await",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Worker__Match' */'@/pages/Worker/Match'), loading: LoadingComponent}),
        "exact": true,
        "title": "可匹配家政员"
      },
      {
        "path": "/worker/workerKind_clue",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Worker__Clue' */'@/pages/Worker/Clue'), loading: LoadingComponent}),
        "exact": true,
        "title": "家政员线索"
      },
      {
        "path": "/worker/workerKind_all",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Worker__worker-all' */'@/pages/Worker/worker-all'), loading: LoadingComponent}),
        "exact": true,
        "title": "全部家政员"
      },
      {
        "path": "/worker/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Worker__WorkerDetail' */'@/pages/Worker/WorkerDetail'), loading: LoadingComponent}),
        "exact": true,
        "title": "家政员详情"
      },
      {
        "path": "/worker/workerInsurance",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Worker__Insurance' */'@/pages/Worker/Insurance'), loading: LoadingComponent}),
        "exact": true,
        "title": "家政员保险"
      },
      {
        "path": "/worker/resume",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Worker__Resume' */'@/pages/Worker/Resume'), loading: LoadingComponent}),
        "exact": true,
        "title": "简历详情"
      },
      {
        "path": "/worker/newResume",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Worker__NewResume' */'@/pages/Worker/NewResume'), loading: LoadingComponent}),
        "exact": true,
        "title": "简历详情"
      },
      {
        "path": "/worker/poster",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Worker__Poster' */'@/pages/Worker/Poster'), loading: LoadingComponent}),
        "exact": true,
        "title": "海报管理"
      },
      {
        "path": "/worker/workerTrainApply",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Worker__Train' */'@/pages/Worker/Train'), loading: LoadingComponent}),
        "exact": true,
        "title": "家政员培训"
      },
      {
        "path": "/worker/workerEnroll",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Worker__WorkerEnroll' */'@/pages/Worker/WorkerEnroll'), loading: LoadingComponent}),
        "exact": true,
        "title": "培训招生"
      },
      {
        "path": "/worker/workerEnroll_order",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Worker__workerEnrollOrder' */'@/pages/Worker/workerEnrollOrder'), loading: LoadingComponent}),
        "exact": true,
        "title": "报名列表"
      },
      {
        "path": "/partner",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Partner__Shop' */'@/pages/Partner/Shop'), loading: LoadingComponent}),
        "exact": true,
        "title": "合伙人管理"
      },
      {
        "path": "/partner/partner_shop",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Partner__Shop' */'@/pages/Partner/Shop'), loading: LoadingComponent}),
        "exact": true,
        "title": "门店合伙人"
      },
      {
        "path": "/partner/partner_sns",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Partner__Community' */'@/pages/Partner/Community'), loading: LoadingComponent}),
        "exact": true,
        "title": "社区合伙人"
      },
      {
        "path": "/partner/partner_third",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Partner__Third' */'@/pages/Partner/Third'), loading: LoadingComponent}),
        "exact": true,
        "title": "第三方合伙人"
      },
      {
        "path": "/setting/company_setting",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setting__CompanyInfo' */'@/pages/Setting/CompanyInfo'), loading: LoadingComponent}),
        "exact": true,
        "title": "企业信息设置"
      },
      {
        "path": "/setting/personal_center",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setting__PersonalCenter' */'@/pages/Setting/PersonalCenter'), loading: LoadingComponent}),
        "exact": true,
        "title": "个人中心"
      },
      {
        "path": "/setting",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setting__Department' */'@/pages/Setting/Department'), loading: LoadingComponent}),
        "exact": true,
        "title": "系统设置"
      },
      {
        "path": "/setting/setting_departmentUser",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setting__Department' */'@/pages/Setting/Department'), loading: LoadingComponent}),
        "exact": true,
        "title": "部门员工"
      },
      {
        "path": "/setting/setting_permission",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setting__Permission' */'@/pages/Setting/Permission'), loading: LoadingComponent}),
        "exact": true,
        "title": "权限管理"
      },
      {
        "path": "/setting/setting_workType",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setting__WorkType' */'@/pages/Setting/WorkType'), loading: LoadingComponent}),
        "exact": true,
        "title": "工种管理"
      },
      {
        "path": "/setting/setting_company",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setting__EnterpriseConfiguration' */'@/pages/Setting/EnterpriseConfiguration'), loading: LoadingComponent}),
        "exact": true,
        "title": "企业设置"
      },
      {
        "path": "/setting/setting_source",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setting__Source' */'@/pages/Setting/Source'), loading: LoadingComponent}),
        "exact": true,
        "title": "枚举配置"
      },
      {
        "path": "/setting/workerEnroll_program",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setting__WorkerEnrollProgram' */'@/pages/Setting/WorkerEnrollProgram'), loading: LoadingComponent}),
        "exact": true,
        "title": "课程管理"
      },
      {
        "path": "/setting/workerEnroll_programPack",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setting__WorkerEnrollPack' */'@/pages/Setting/WorkerEnrollPack'), loading: LoadingComponent}),
        "exact": true,
        "title": "套餐管理"
      },
      {
        "path": "/setting/setting_store",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setting__Store' */'@/pages/Setting/Store'), loading: LoadingComponent}),
        "exact": true,
        "title": "小店管理"
      },
      {
        "path": "/setting/setting_companyUserSalary",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setting__companyUserSalary' */'@/pages/Setting/companyUserSalary'), loading: LoadingComponent}),
        "exact": true,
        "title": "工资管理"
      },
      {
        "path": "/setting/setting_msg",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setting__send-message' */'@/pages/Setting/send-message'), loading: LoadingComponent}),
        "exact": true,
        "title": "群发消息"
      },
      {
        "path": "/setting/setting_customerCrite",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setting__CustomerCrite' */'@/pages/Setting/CustomerCrite'), loading: LoadingComponent}),
        "exact": true,
        "title": "评级管理"
      },
      {
        "path": "/setting/setting_faq",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setting__FAQ' */'@/pages/Setting/FAQ'), loading: LoadingComponent}),
        "exact": true,
        "title": "FAQ-功能引导"
      },
      {
        "path": "/setting/setting_feedback",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setting__Feedback' */'@/pages/Setting/Feedback'), loading: LoadingComponent}),
        "exact": true,
        "title": "系统反馈"
      },
      {
        "path": "/setting/setting_posterTemplate",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setting__PosterTemplate' */'@/pages/Setting/PosterTemplate'), loading: LoadingComponent}),
        "exact": true,
        "title": "海报模板管理"
      },
      {
        "path": "/dynodes",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Redouble__User' */'@/pages/Redouble/User'), loading: LoadingComponent}),
        "exact": true,
        "title": "倍增系统"
      },
      {
        "path": "/dynodes/dynodes_user",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Redouble__User' */'@/pages/Redouble/User'), loading: LoadingComponent}),
        "exact": true,
        "title": "用户管理"
      },
      {
        "path": "/dynodes/dynodes_order",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Redouble__Order' */'@/pages/Redouble/Order'), loading: LoadingComponent}),
        "exact": true,
        "title": "订单管理"
      },
      {
        "path": "/dynodes/dynodes_withdraw",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Redouble__Withdraw' */'@/pages/Redouble/Withdraw'), loading: LoadingComponent}),
        "exact": true,
        "title": "提现管理"
      },
      {
        "path": "/dynodes/dynodes_withdraw/details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Redouble__Withdraw__Details' */'@/pages/Redouble/Withdraw/Details'), loading: LoadingComponent}),
        "exact": true,
        "title": "提现详情"
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404'), loading: LoadingComponent})
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
